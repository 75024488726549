@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

* {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* google button */
.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e2e8f0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

/* .gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
} */

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

/* custom search icon */
/* #2684FF */
.custom-search-with-icon {
  outline: none;
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%);
  transition: all 100ms;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding-left: 30px;
}

.custom-search-with-icon:focus {
  box-shadow: 0 0 0 1px #2684ff;
  outline: none;
  transition: all 100ms;
  border: 1px solid #2684ff;
}

input.custom-search-with-icon::placeholder {
  font-size: 13px;
}

.custom-date-picker-schedule-post input {
  outline: none;
  box-shadow: none;
  border: 1px solid hsl(0, 0%, 80%);
  transition: all 100ms;
  border-radius: 4px;
  height: 38px;
  line-height: 38px;
  padding-left: 5px;
}

.custom-date-picker-schedule-post input:focus {
  box-shadow: 0 0 0 1px #2684ff;
  outline: none;
  transition: all 100ms;
  border: 1px solid #2684ff;
}

.custom-color-icon g {
  fill: #737373 !important;
}

.custom-color-icon:hover g {
  fill: #1672c9 !important;
}

.relative {
  position: relative;
}

.flex-direction-responsive {
  flex-direction: row;
}

.custom-text-responsive {
  font-size: 16px !important;
}

.custom-padding-x-responsive {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-padding-add-plus-x-responsive {
  padding-left: 30px;
  padding-right: 30px;
}

.custom-height-responsive {
  height: calc(100vh - 170px);
}

@media screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }

  .flex-direction-responsive {
    flex-direction: column;
    align-items: flex-start !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .custom-text-responsive {
    font-size: 12px !important;
  }

  .MuiFormLabel-root {
    font-size: 12px !important;
  }

  .custom-padding-x-responsive {
    padding-left: 10px;
    padding-right: 10px;
  }

  .custom-height-responsive {
    height: calc(100vh - 230px);
  }

  .custom-padding-add-plus-x-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .custom-input-chat-mui .MuiInputBase-input {
    padding-left: 0px !important;
  }

  .custom-input-chat-mui .MuiFormLabel-root {
    padding-left: 0px !important;
  }
}

.sigCanvas {
  border-radius: 8px;
  margin-top: 30px;
}

/* custom calendar */
.rdp-dropdown_year {
  margin-top: 2px;
}

.rdp-dropdown_year > div {
  display: none;
}

.rdp-dropdown_month > select,
.rdp-dropdown_month svg {
  display: none;
}

.rdp-dropdown_month div {
  text-align: center;
}

.rdp-vhidden {
  display: none;
}

.rdp-dropdown {
  font-size: 14px;
}

select.rdp-dropdown[name="years"] {
  @apply flex w-full items-center justify-between rounded-md border border-input bg-background px-2 py-1 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1;
}

select.rdp-dropdown option {
  font-size: 14px;
}
